<template>
  <div class="404-page">
    <Page404 />
  </div>
</template>

<script>
import Page404 from "@/components/errors/404";

export default {
  name: "LimeLoans404page",

  components: { Page404 },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
