<template>
  <div class="404-page">
    <div class="404">
      <v-card color="white" width="100%" flat>
        <v-container class="py-10">
          <v-row>
            <!-- There are no such pages -->
            <v-col cols="12" lg="6">
              <h3 class="heading-h3">There are no such pages</h3>

              <p class="b-body-1 mt-4">
                <router-link
                  class="b-body-1 primary--text"
                  :to="{ name: 'HomePage' }"
                >
                  Refresh
                </router-link>
                the page or return to the
                <router-link
                  class="b-body-1 primary--text"
                  :to="{ name: 'HomePage' }"
                >
                  main page
                </router-link>
              </p>

              <v-img
                alt="404"
                class="404 mt-2"
                contain
                src="@/assets/img/errors/404/404.svg"
                max-width="498"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeLoans404",

  data() {
    return {};
  },

  mounted() {},

  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
